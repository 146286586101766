const initialState = {
	show: false,
	type: 'warning',
	message: '',
	onClick: null,
};

export default function toast(state = initialState, {type, payload}) {
	switch (type) {
	case 'SET_TOAST_MESSAGE':
		return {
			...state,
			show: true,
			toastType: payload.toastType,
			message: payload.message,
			onClick: payload.onClick,
		};
	case 'REMOVE_TOAST':
		return {
			...state,
			show: false,
			onClick: null,
		};
	default:
		return state;
	}
}