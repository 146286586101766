import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faExclamationCircle,
	faTimesCircle,
	faCheck,
} from '@fortawesome/pro-solid-svg-icons';

import {getUIKitColorHex} from 'utils/utils';
import {removeToast} from '../../store/actions/toast';

import './style.scss';

// Local Constants
const TOASTER_TYPES = ['danger', 'warning', 'success'];
const TOASTER_COLOR = {
	danger: getUIKitColorHex('errorDefault'),
	warning: getUIKitColorHex('warningHover'),
	success: getUIKitColorHex('successDefault'),
};
const TOASTER_BACKGROUND = {
	danger: getUIKitColorHex('errorDefault'),
	warning: getUIKitColorHex('warningDefault'),
	success: getUIKitColorHex('successDefault'),
};
const TOASTER_ICON = {
	danger: faExclamationCircle,
	warning: faExclamationCircle,
	success: faCheck,
};
const DEFAULT_TYPE = TOASTER_TYPES[0];

function Toaster(props) {
	let autoHideToasterCountdown;
	const toastData = useSelector(state => state.toast);

	const toasterShowDelay = 5000;
	const renderDelay = 300;
	const dispatch = useDispatch();

	const [mountToaster, setMountToaster] = useState(false);
	const [showContent, setShowContent] = useState(false);
	const dismissToaster = () => dispatch(removeToast());

	const openToaster = async () => {
		setMountToaster(true); // mount toaster component
		setTimeout(() => {
			setShowContent(true); // show toaster
			autoHideToasterCountdown = setTimeout(dismissToaster, toasterShowDelay);
		}, renderDelay);
	};

	const closeToaster = async () => {
		if (mountToaster) {
			setShowContent(false); // hide toaster
			setTimeout(() => {
				setMountToaster(false);
			}, renderDelay); // unmount toaster component after animation finished
		}
	};

	useEffect(() => {
		toastData.show ? openToaster() : closeToaster();
		return () => {
			clearTimeout(autoHideToasterCountdown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, toastData]);
	const type = TOASTER_TYPES.includes(toastData.toastType)
		? toastData.toastType
		: DEFAULT_TYPE;
	return (
		mountToaster && (
			<div
				onClick={toastData.onClick}
				className={`Toaster ${showContent && 'show'}`}>
				<div
					className="toast-content-container"
					style={{backgroundColor: TOASTER_BACKGROUND[type]}}>
					<div className="exclamation-icon-container">
						<FontAwesomeIcon
							icon={TOASTER_ICON[type]}
							className="exclamation-icon"
						/>
					</div>
					<div className="toast-content">
						<div
							className="toast-message"
							style={{color: TOASTER_COLOR[type]}}>
							{toastData.message}
						</div>
						<div
							className="icon-container"
							onClick={e => {
								e.stopPropagation();
								dismissToaster();
							}}>
							<FontAwesomeIcon
								icon={faTimesCircle}
								className="close-icon" />
						</div>
					</div>
				</div>
			</div>
		)
	);
}
Toaster.defaultProps = {
	message: 'smehting here',
	type: DEFAULT_TYPE,
	show: false,
};

Toaster.propTypes = {
	message: PropTypes.string,
	type: PropTypes.string,
	show: PropTypes.bool,
};
export default Toaster;
