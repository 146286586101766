import {ACTIONS} from 'utils/constants';

export const setAuthToken = payload => ({
	type: ACTIONS.SET_AUTH_TOKEN,
	payload,
});

export const removeAuthToken = () => ({
	type: ACTIONS.REMOVE_AUTH_TOKEN,
});

export const setPromoAuthToken = payload => ({
	type: ACTIONS.SET_PROMO_AUTH_TOKEN,
	payload,
});

export const removePromoAuthToken = () => ({
	type: ACTIONS.REMOVE_PROMO_AUTH_TOKEN,
});

export const setUserAuthToken = payload => ({
	type: ACTIONS.SET_USER_AUTH_TOKEN,
	payload,
});

export const removeUserAuthToken = () => ({
	type: ACTIONS.REMOVE_USER_AUTH_TOKEN,
});

export const setOrderAuthToken = payload => ({
	type: ACTIONS.SET_ORDER_AUTH_TOKEN,
	payload,
});

export const removeOrderAuthToken = () => ({
	type: ACTIONS.REMOVE_ORDER_AUTH_TOKEN,
});

export const setFromNativeApp = payload => ({
	type: ACTIONS.SET_FROM_NATIVE_APP,
	payload,
});
export const setIsNativeWithoutTab = payload => ({
	type: ACTIONS.SET_IS_NATIVE_WITHOUT_TAB,
	payload,
});
export const setUserData = payload => ({
	type: ACTIONS.SET_USER_DATA,
	payload,
});
