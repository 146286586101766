import {forwardRef, useEffect} from 'react';
import isEmpty from 'lodash/isEmpty';
import base64 from 'base-64';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import Axios from 'axios';

// Global utils
import {URLS} from 'utils/constants';

const HangryLogin = forwardRef(
	(
		{
			onSuccess = () => null,
			onFailed = () => null,
			debugUrl = '',
			refreshTokenOnMount = false,
			mode = 'newWindow',
		},
		ref,
	) => {
		const authUrl =
			isEmpty(debugUrl) || debugUrl === 'production'
				? 'https://auth.ishangry.com'
				: debugUrl === 'development'
					? 'https://auth-dev.ishangry.com'
					: debugUrl;

		const setRef = () => {
			const incomingRef = {
				login,
				refreshToken,
				logout,
			};
			//eslint-disable-next-line
			if (ref && ref.hasOwnProperty('current')) {
				ref.current = incomingRef;
			} else if (typeof ref === 'function') {
				ref(incomingRef);
			}
		};

		const refreshToken = async () => {
			const refreshTokenTimestamp = Cookies.get('refreshTokenTimestamp');
			const tokens = Cookies.getJSON('hangryTokens') || {};
			if (!refreshTokenTimestamp && tokens.auth) {
				await Axios({
					method: 'GET',
					url: `${URLS.AUTH_SERVICE_BASE_URL}/auth/refreshToken`,
					headers: {
						Authorization: `Bearer ${tokens.auth}`,
					},
				})
					.then(({data}) => {
						const tokens = data.tokens.reduce(
							(previousValue, currentValue) => ({
								...previousValue,
								[currentValue.service]: currentValue.token,
							}),
							{},
						);
						updateCookies(tokens);
						onSuccess(tokens, window.location.href);
					})
					.catch(error => {
						onFailed(error);
					});
			}
		};

		const logout = targetUrl => {
			Cookies.remove('hangryTokens');
			const nextUrl = encodeURIComponent(targetUrl || window.location.href);
			const origin = encodeURIComponent(window.location.origin);
			window.location.href = `${authUrl}/logout?origin=${origin}&next=${nextUrl}`;
		};

		const login = targetUrl => {
			const nextUrl = encodeURIComponent(targetUrl || window.location.href);
			const origin = encodeURIComponent(window.location.origin);
			if (mode === 'redirect') {
				window.location.href = `${authUrl}?origin=${origin}&next=${nextUrl}`;
			} else {
				window.open(
					`${authUrl}?origin=${origin}&next=${nextUrl}`,
					'hangryAuth',
					`
						width=500,
						height=700
					`,
				);
			}
		};

		const onTokenMessageReceived = (encodedTokens, nextUrl) => {
			try {
				const decodedTokens = base64.decode(encodedTokens);
				const parsedTokens = JSON.parse(decodedTokens);
				updateCookies(parsedTokens);
				onSuccess(parsedTokens, nextUrl);
			} catch (error) {
				onFailed(error);
			}
		};

		const updateCookies = tokens => {
			Cookies.set('hangryTokens', tokens, {expires: 30});
			Cookies.set('refreshTokenTimestamp', Date.now() + 24 * 3600 * 1000, {
				expires: 1,
			});
		};

		useEffect(() => {
			setRef();
			if (mode === 'redirect') {
				const url = new URL(window.location.href);
				const access_token = url.searchParams.get('access_token');
				const signUpWithReferral = url.searchParams.get('signUpWithReferral');
				const nextUrl = url.searchParams.get('nextUrl');
				url.searchParams.delete('access_token');
				url.searchParams.delete('signUpWithReferral');
				url.searchParams.delete('nextUrl');
				if (signUpWithReferral === 'true') {
					Cookies.set('signUpWithReferral', true, {expires: 1});
				}
				if (access_token) {
					window.history.replaceState(null, '', url);
					onTokenMessageReceived(access_token, nextUrl);
				}
			} else {
				window.addEventListener(
					'message',
					e => {
						try {
							if (
								[
									'http://localhost:3000',
									'https://auth.ishangry.com',
									'https://auth-dev.ishangry.com',
								].includes(e.origin)
							) {
								const parsedData =
								typeof e.data === 'string' ? JSON.parse(e.data) : {};
								if (parsedData.access_token) {
									onTokenMessageReceived(
										parsedData.access_token,
										parsedData.nextUrl,
									);
								}
								if (parsedData.signUpWithReferral) {
									Cookies.set('signUpWithReferral', true, {expires: 1});
								}
							}
						} catch (error) {
							onFailed(error);
							console.log(error);
						}
					},
					false,
				);
			}
			refreshTokenOnMount && refreshToken();
			//eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return null;
	},
);

HangryLogin.propTypes = {
	onSuccess: PropTypes.func, // forwards tokens and nextUrl
	onFailed: PropTypes.func,
	debugUrl: PropTypes.string,
	refreshTokenOnMount: PropTypes.bool,
	mode: PropTypes.oneOf(['redirect', 'newWindow']),
};

export default HangryLogin;
