import {
	from,
	HttpLink,
	ApolloLink,
	ApolloClient,
	InMemoryCache,
} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import Cookies from 'js-cookie';

// Global utils
import crashLogger from 'utils/logger';
import {COOKIE_NAMES, URLS} from 'utils/constants';
import {isFromNative, sendMessageToNative} from 'utils/utils';

const httpLink = new HttpLink({uri: URLS.POINTS_SERVICE_BASE_URL});
const authMiddleware = new ApolloLink((operation, forward) => {
	// add the authorization to the headers
	operation.setContext(({headers = {}}) => ({
		headers: {
			...headers,
			authorization: Cookies.get(COOKIE_NAMES.AUTH_KEY) || null,
		},
	}));

	return forward(operation);
});

const tokenErrorLink = onError(({graphQLErrors, networkError}) => {
	if (graphQLErrors) {
		if (graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN')) {
			if (isFromNative()) {
				sendMessageToNative({error: 'expiredToken'});
			} else {
				Cookies.remove(COOKIE_NAMES.AUTH_KEY);
				Cookies.remove(COOKIE_NAMES.PROMO_AUTH_KEY);
				Cookies.remove(COOKIE_NAMES.USER_AUTH_KEY);
				window.hangryAuthRef && window.hangryAuthRef.logout(window.location.origin);
			}
		} else {
			graphQLErrors.forEach(({message, locations, path, extensions}) => {
				const error = new Error(message);
				error.graphql = {
					message: message,
					extensions: JSON.stringify(extensions),
					locations: JSON.stringify(locations),
					path: JSON.stringify(path),
				};
				crashLogger.error(error);
			});
		}
	}
	if (networkError) {
		console.log(`[Network error]: ${networkError}`);
	}
});

const client = new ApolloClient({
	cache: new InMemoryCache({addTypename: false}),
	link: from([tokenErrorLink, authMiddleware, httpLink]),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'no-cache',
		},
	},
});

export default client;
