import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ApolloProvider} from '@apollo/client';
import gqlClient from './utils/gqlClient';

import './index.css';

import App from './App';
import store from 'store';
import * as serviceWorker from './serviceWorker';

const renderApp = () =>
// eslint-disable-next-line react/no-render-return-value
	ReactDOM.render(
		<ApolloProvider client={gqlClient}>
			<Provider store={store}>
				<App />
			</Provider>
		</ApolloProvider>,
		document.getElementById('root'),
	);

// Hot reload
// if (process.env.NODE_ENV !== 'production' && module.hot) {
//   module.hot.accept('./App', renderApp);
// }

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
