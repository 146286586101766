import {useEffect, useRef, useLayoutEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import jwt from 'jsonwebtoken';

import {COOKIE_NAMES} from 'utils/constants';
import {sendMessageToNative} from 'utils/utils';
import {
	setAuthToken,
	setOrderAuthToken,
	setPromoAuthToken,
	setUserAuthToken,
} from 'store/actions/auth';
import Cookies from 'js-cookie';

const invalidJWT = token => {
	if (!token) return true;

	const decoded = jwt.decode(token);
	const now = Math.trunc(new Date().getTime() / 1000);
	return !decoded || now > decoded.exp;
};

export const useToken = (type = COOKIE_NAMES.AUTH_KEY) => {
	const dispatch = useDispatch();

	const token = useSelector(state => state.auth[type]);
	const queryToken = new URLSearchParams(window.location.search).get(type);

	let setAuthFunction;
	switch (type) {
	case COOKIE_NAMES.AUTH_KEY:
		setAuthFunction = setAuthToken;
		break;
	case COOKIE_NAMES.PROMO_AUTH_KEY:
		setAuthFunction = setPromoAuthToken;
		break;
	case COOKIE_NAMES.USER_AUTH_KEY:
		setAuthFunction = setUserAuthToken;
		break;
	case COOKIE_NAMES.ORDER_AUTH_KEY:
		setAuthFunction = setOrderAuthToken;
		break;
	default:
		setAuthFunction = setAuthToken;
	}

	const removeTokenAndLogout = () => {
		sendMessageToNative({error: 'expiredToken'});
		Cookies.remove(COOKIE_NAMES.AUTH_KEY);
		Cookies.remove(COOKIE_NAMES.PROMO_AUTH_KEY);
		Cookies.remove(COOKIE_NAMES.USER_AUTH_KEY);
		Cookies.remove(COOKIE_NAMES.ORDER_AUTH_KEY);
		window.hangryAuthRef && window.hangryAuthRef.logout(window.location.origin);
	};

	useEffect(() => {
		const cookieToken = Cookies.get(type);
		if (queryToken) {
			if (invalidJWT(queryToken)) return removeTokenAndLogout();

			Cookies.set(type, queryToken, {expires: 30});
			dispatch(setAuthFunction({token: queryToken, source: 'query'}));
		} else if (cookieToken) {
			if (invalidJWT(cookieToken)) return removeTokenAndLogout();

			!token &&
        dispatch(setAuthFunction({token: cookieToken, source: 'localstorage'}));
		} else if (token && invalidJWT(token)) {
			removeTokenAndLogout();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryToken, token, dispatch, removeTokenAndLogout]);

	return token;
};

const getScrollPosition = element => {
	const target = element.current;
	if (!target) return 0;

	const position = Math.trunc(target.scrollLeft / target.offsetWidth);
	return position;
};

export const useIndicatorPosition = (effect, deps, element) => {
	const position = useRef(getScrollPosition(element));

	const handleScroll = () => {
		const cur = getScrollPosition(element);
		if (position.current !== cur) {
			effect({prev: position.current, cur});
			position.current = cur;
		}
	};

	useLayoutEffect(() => {
		const target = element.current;
		if (target) {
			target.addEventListener('scroll', handleScroll);
			return () => target.removeEventListener('scroll', handleScroll);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps, element.current, handleScroll]);
};
