import cloudinaryCore from 'cloudinary-core';
import isURL from 'validator/es/lib/isURL';
import Cookies from 'js-cookie';
import Axios from 'axios';
import url from 'url';

// Global utils
import {LOCAL_STORAGE_USER, COOKIE_NAMES, URLS} from 'utils/constants';
import analytics from 'utils/analytics';
import crashLogger from 'utils/logger';
import {goTo} from 'utils/navigation';

// Redux
import {
	setAuthToken,
	setPromoAuthToken,
	setUserAuthToken,
	setOrderAuthToken,
	setUserData,
	setFromNativeApp,
	setIsNativeWithoutTab,
} from 'store/actions/auth';
import store from 'store';

import PAGE_CONSTANTS from 'assets/styles/_hangry-constants.module.scss';

export const fetchUserData = async () => {
	const authToken = store.getState().auth.authToken;
	if (authToken) {
		await Axios({
			method: 'GET',
			url: `${URLS.AUTH_SERVICE_BASE_URL}/user/profile`,
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		})
			.then(({data}) => {
				localStorage.setItem(LOCAL_STORAGE_USER, btoa(JSON.stringify(data)));
				store.dispatch(setUserData(data));
			})
			.catch(error => {
				console.log(error);
			});
	}
};

export const fetchRatingData = async orderId => {
	const orderToken = store.getState().auth.orderToken;
	if (orderToken) {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await Axios({
				method: 'GET',
				url: `${URLS.ORDER_SERVICE_BASE_URL}/order/order-items`,
				headers: {
					Authorization: `Bearer ${orderToken}`,
				},
				params: {
					orderId,
				},
			});
			return response;
		} catch (error) {
			throw error;
		}
	}
};

export const postRatingData = async data => {
	const orderToken = store.getState().auth.orderToken;
	if (orderToken) {
		await Axios({
			method: 'POST',
			url: `${URLS.ORDER_SERVICE_BASE_URL}/order/submit-rating`,
			headers: {
				Authorization: `Bearer ${orderToken}`,
			},
			data,
		});
	}
};

export const checkMSAvailability = async () => {
	const orderToken = Cookies.get(COOKIE_NAMES.ORDER_AUTH_KEY);
	const isLoggedIn = Cookies.get(COOKIE_NAMES.AUTH_KEY);
	if (!isLoggedIn) {
		return {};
	}
	try {
		const {data} = await Axios({
			method: 'GET',
			url: `${URLS.ORDER_SERVICE_BASE_URL}/mystery/active`,
			headers: {
				Authorization: orderToken,
			},
		});

		if (data.targetLink) {
			data.targetLink = new URL(data.targetLink);
			data.targetLink.searchParams.append('token', orderToken);
			data.targetLink = data.targetLink.href;
		}

		return data;
	} catch (error) {
		console.log(error);
	}
};

export const onLoginTokenReceived = (tokens, nextUrl) => {
	logAnalytics('loyalty_webview_login_success');
	if (tokens.loyalty) {
		Cookies.set(COOKIE_NAMES.AUTH_KEY, tokens.loyalty, {expires: 30});
		store.dispatch(setAuthToken({token: tokens.loyalty, source: 'query'}));
	}
	if (tokens.promo) {
		Cookies.set(COOKIE_NAMES.PROMO_AUTH_KEY, tokens.promo, {expires: 30});
		store.dispatch(setPromoAuthToken({token: tokens.promo, source: 'query'}));
	}
	if (tokens.auth) {
		Cookies.set(COOKIE_NAMES.USER_AUTH_KEY, tokens.auth, {expires: 30});
		store.dispatch(setUserAuthToken({token: tokens.auth, source: 'query'}));
	}
	if (tokens.order) {
		Cookies.set(COOKIE_NAMES.ORDER_AUTH_KEY, tokens.order, {expires: 30});
		store.dispatch(setOrderAuthToken({token: tokens.order, source: 'query'}));
	}
	window.location.replace(nextUrl);
};

export const sendWhatsappMessage = (phoneNumber, text = '') => {
	const cleanPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
	const query = text.length ? `&text=${encodeURIComponent(text)}` : '';
	window.open(`http://api.whatsapp.com/send?phone=${cleanPhoneNumber}${query}`);
};

export const interpolate = (number, inOne, inTwo, outOne, outTwo) => {
	const result =
    ((number - inOne) * (outTwo - outOne)) / (inTwo - inOne) + outOne;
	if (result > Math.max(outOne, outTwo)) {
		return Math.max(outOne, outTwo);
	} else if (result < Math.min(outOne, outTwo)) {
		return Math.min(outOne, outTwo);
	}
	return result;
};

export const isMobile = () => {
	const toMatch = [
		/Android/i,
		/webOS/i,
		/iPhone/i,
		/iPad/i,
		/iPod/i,
		/BlackBerry/i,
		/Windows Phone/i,
	];
	return toMatch.some(toMatchItem => {
		return navigator.userAgent.match(toMatchItem);
	});
};

export const isIos = () => {
	const toMatch = [/iPhone/i, /iPad/i, /iPod/i];

	return toMatch.some(toMatchItem => {
		return navigator.userAgent.match(toMatchItem);
	});
};

export const iosGapClassname = type => {
	if (isFromNative() && isIos()) {
		switch (type) {
		case 'padding':
			return 'ios-gap-padding';
		case 'margin':
			return 'ios-gap-margin';
		default:
			return 'ios-gap';
		}
	} else {
		return '';
	}
};

export const shortenMoney = money => {
	const shortedMoney = money / 1000;
	if (shortedMoney >= 1) {
		return `${shortedMoney}rb`;
	} else {
		return money;
	}
};

export const logAnalytics = (eventName, param = {}) => {
	analytics.logEvent(eventName, param);
};

export const promptLogin = preferredPage => {
	if (isFromNative()) {
		logAnalytics('loyalty_prompt_login_native');
		openNativePage('login');
	} else {
		logAnalytics('loyalty_prompt_login_webview');
		window.hangryAuthRef && window.hangryAuthRef.login(preferredPage);
	}
};

export const openNewWebviewStack = (route, skipLoginCheck) => {
	if (isFromNative() && !isNativeWithoutTab()) {
		sendMessageToNative({
			function: {
				key: 'openNewWebviewStack',
				path: route,
				skipLoginCheck,
			},
		});
	} else {
		const isLoggedIn = Cookies.get(COOKIE_NAMES.AUTH_KEY);
		if (skipLoginCheck || isLoggedIn) {
			goTo(route);
		} else {
			promptLogin();
		}
	}
};

export const openLoyaltyFAQ = (externalLink =
'https://ishangry.notion.site/Hangry-Poin-Loyalty-58ef9f751bfa4ff4a4f12ab64b6c7323') => {
	if (isFromNative()) {
		sendMessageToNative({
			externalLink,
		});
	} else {
		window.open(externalLink);
	}
};

export const cloudinaryImage = (cloudinaryUrl = '', config = {}) => {
	try {
		const isCloudinaryUrl = isURL(cloudinaryUrl, {
			require_protocol: false,
			host_whitelist: ['res.cloudinary.com'],
		});
		if (isCloudinaryUrl) {
			const {pathname} = url.parse(cloudinaryUrl);
			const imageId = pathname.slice(1).split('/').slice(4).join('/');
			const imageVersion = pathname.slice(1).split('/')[3].slice(1);
			const cl = cloudinaryCore.Cloudinary.new({
				cloud_name: 'dgsgylfvr',
			}).init();
			return cl.url(imageId, {...config, version: imageVersion});
		} else {
			return cloudinaryUrl;
		}
	} catch (error) {
		// Fallback by returning original url if the given url is not a valid cloudinary url
		return cloudinaryUrl;
	}
};

// Methods related to native
export const isFromNative = () => store.getState().auth.fromNativeApp;
export const isNativeWithoutTab = () => store.getState().auth.isNativeWithoutTab;

export const detectStatusBarHeight = () => {
	const sessionHeight = sessionStorage.getItem('statusBarHeight') || 0;
	const height =
    new URLSearchParams(window.location.search).get('statusBarHeight') ||
    Number(sessionHeight);
	if (height) {
		document.documentElement.style.setProperty(
			'--status-bar-height',
			height + 'px',
		);
		sessionStorage.setItem('statusBarHeight', height);
	} else {
		document.documentElement.style.setProperty(
			'--status-bar-height',
			24 + 'px',
		);
	}
};

export const detectFromNativeApp = async () => {
	const sessionIsFromNative = sessionStorage.getItem('isFromNative') || false;
	const sessionIsNativeWithoutTab = sessionStorage.getItem('isNativeWithoutTab') || false;
	const isFromNative =
    new URLSearchParams(window.location.search).get('fromNativeApp') ||
    Boolean(sessionIsFromNative);
	if (isFromNative) {
		store.dispatch(setFromNativeApp(true));
		detectStatusBarHeight();
		sessionStorage.setItem('isFromNative', true);
		const isNativeWithoutTab =
   		new URLSearchParams(window.location.search).get('isNativeWithoutTab') || Boolean(sessionIsNativeWithoutTab);
		if (isNativeWithoutTab) {
			store.dispatch(setIsNativeWithoutTab(true));
			sessionStorage.setItem('isNativeWithoutTab', true);
		}
	}
};

export const detectUserData = async () => {
	const userDataFromParam = new URLSearchParams(window.location.search).get(
		'user',
	);
	const userDataFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_USER);
	const userData = userDataFromParam || userDataFromLocalStorage;
	if (userData) {
		const decodedData = JSON.parse(atob(userData));
		store.dispatch(setUserData(decodedData));
		localStorage.setItem(LOCAL_STORAGE_USER, userData);
	} else {
		fetchUserData();
	}
};

export const setGlobalVariable = (obj = {}) => {
	if (window.HangryPoint) {
		window.HangryPoint = {...window.HangryPoint, ...obj};
	} else {
		window.HangryPoint = {...obj};
	}
};

export const sendMessageToNative = message => {
	try {
		window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
	} catch (error) {
		console.log(error);
	}
};

export const openNativePage = (path = '') => {
	if (isFromNative()) {
		sendMessageToNative({hook: 'beforeNavigate', path});
	} else {
		switch (path.toLowerCase()) {
		case 'scan':
			goTo('/scan');
			break;
		case 'order':
			logAnalytics('redirect_buy_widget_download_app');
			downloadApp();
			break;
		default:
		}
	}
};

export const openDeeplink = deeplink => {
	sendMessageToNative({deeplink});
};

export const openAppSurvey = () => {
	sendMessageToNative({function: {key: 'openAppSurvey'}});
};

export const showNativeBlockedLoyaltyModal = () => {
	sendMessageToNative({function: {key: 'showBlockedLoyaltyModal'}});
};

export const openBonusPointBottomsheet = () => {
	sendMessageToNative({function: {key: 'openBonusPointBottomsheet'}});
};

export const downloadApp = () => {
	window.open('https://hangry.page.link/loyaltydownloadapp');
};

export const goToRewardOrDownload = () => {
	window.open('https://hangry.page.link/RfM6');
};

export const goToMenuRp1OrDownload = () => {
	window.open('https://hangry.page.link/4wMC');
};

export const importRetry = async (importFn, retries = 2, interval = 1000) => {
	try {
		return await importFn();
	} catch (error) {
		if (retries) {
			await new Promise(resolve => setTimeout(resolve, interval));
			return importRetry(importFn, retries - 1, interval);
		} else {
			crashLogger.error(error);
		}
	}
};

export const moneyFormat = money => {
	return money ? money.toString(10).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0;
};

export const pxToVwMaxed = (pxValue, includePxUnit = false) => {
	const parsedPxValue = parseInt(pxValue);
	const designMobileWidth = parseInt(PAGE_CONSTANTS.designMobileWidth);

	const deviceSize = (parsedPxValue * window.innerWidth) / designMobileWidth;


	if (parsedPxValue < 0) {
		const maxResult = Math.max(deviceSize, parsedPxValue);
		return includePxUnit ? `${maxResult}px` : maxResult;
	}
	const minResult = Math.min(deviceSize, parsedPxValue);
	return includePxUnit ? `${minResult}px` : minResult;
};

export const getUIKitColorHex = colorCode => {
	return getComputedStyle(document.body).getPropertyValue(`--${colorCode}`);
};


export const extractQRPayload = (payload, isExtracted = false) => {
	try {
		let paramValue;
		if (!isExtracted) {
			const url = new URL(payload);
			paramValue = url.searchParams.get('q') || url.searchParams.get('qrCodeData');
		} else {
			paramValue = payload;
		}
		if (paramValue) {
			const qrCodeComponents = paramValue.split(':');
			if (![2, 3].includes(qrCodeComponents.length)) {
				return {valid: false, format: undefined, qrCode: paramValue};
			}
			const iv = qrCodeComponents[0];
			const isQrValidAsOldFormat = Buffer.from(iv, 'base64').length === 16;
			// New format
			const isQrValidAsNewFormat =
				qrCodeComponents[0].length === 32 && qrCodeComponents[1].length === 3;
			const isQrValidAsAlternateFormat = qrCodeComponents.length === 2;
			// Return
			if (isQrValidAsOldFormat) {
				return {valid: true, format: 'v1', qrCode: paramValue};
			} else if (isQrValidAsNewFormat || isQrValidAsAlternateFormat) {
				return {valid: true, format: 'v2', qrCode: paramValue};
			} else {
				return {valid: false, format: undefined, qrCode: paramValue};
			}
		} else {
			return {valid: false, format: undefined, qrCode: paramValue};
		}
	} catch (e) {
		return payload;
	}
};
