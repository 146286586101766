import BugsnagPluginReact from '@bugsnag/plugin-react';
import {isEmpty, get} from 'lodash';
import Bugsnag from '@bugsnag/js';

// Global utils
import {RELEASE_STAGE} from 'utils/constants';
import {isFromNative} from 'utils/utils';

// Redux
import store from 'store';

import {version} from '../../package.json';

class BugsnagClass {
	constructor() {
		this.bugsnagEnabled = process.env.NODE_ENV === 'production';
	}
	setBugsnagObjectMetadata(report, errObj) {
		Object.keys(errObj).forEach(key => {
			report.addMetadata('metadata', key, errObj[key]);
		});
	}
	async initialize() {
		try {
			if (this.bugsnagEnabled) {
				this.Bugsnag = Bugsnag.start({
					apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
					plugins: [new BugsnagPluginReact()],
					releaseStage: RELEASE_STAGE,
					autoBreadcrumbs: true,
					appVersion: version,
					onError: event => {
						const user = store.getState()?.auth?.user;
						if (!isEmpty(user)) {
							const {id, email, name} = user;
							event.setUser(id, email, name);
						}
						event.addMetadata('metadata', {
							isFromNative: isFromNative(),
						});
					},
				});
			}
		} catch (e) {
			console.log(e);
		}
	}
	error(err) {
		if (this.Bugsnag) {
			Bugsnag.notify(err, report => {
				if (get(err, 'response.config')) {
					const {headers, method, params, data, url} = err.response.config;
					this.setBugsnagObjectMetadata(report, {
						headers: JSON.stringify(headers),
						params: JSON.stringify(params),
						requestData: JSON.stringify(data),
						responseData: JSON.stringify(err.response.data),
						status: err.response.status,
						method: method,
						url: url,
					});
				} else if (err.graphql) {
					this.setBugsnagObjectMetadata(report, err.graphql);
				} else {
					report.addMetadata('metadata', 'json', err);
				}
			});
		}
	}
}

export default new BugsnagClass();
