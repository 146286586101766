const initialState = {};

export default function toast(state = initialState, {type, payload}) {
	switch (type) {
	case 'SET_REMOTE_CONFIG':
		return payload;
	default:
		return state;
	}
}
