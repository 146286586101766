import firebase from 'firebase/app';
import 'firebase/remote-config';

// Global utils
import crashLogger from 'utils/logger';
import {RELEASE_STAGE} from 'utils/constants';

// Redux
import store from 'store';
import {setRemoteConfig} from 'store/actions/remoteConfig';

class FirebaseRemoteConfig {
	constructor() {
		this.cacheDuration = RELEASE_STAGE === 'development' ? 0 : 3600000;
		this.remoteConfigValue = {};
		this.getRemoteConfig();
	}
	async getRemoteConfig() {
		try {
			const remoteConfig = firebase.remoteConfig();
			remoteConfig.settings.minimumFetchIntervalMillis = this.cacheDuration;
			await remoteConfig.fetchAndActivate();
			const fetchObject = remoteConfig.getAll();
			Object.keys(fetchObject).forEach(key => {
				const parsedJson = JSON.parse(fetchObject[key]._value);
				this.remoteConfigValue[key] = parsedJson;
			});
			store.dispatch(setRemoteConfig(this.remoteConfigValue));
		} catch (error) {
			crashLogger.error(error);
		}
	}
}

export default new FirebaseRemoteConfig();
