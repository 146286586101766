import React from 'react';
import {useHistory} from 'react-router-dom';

import localization from 'localization';
import {sendMessageToNative} from 'utils/utils';

// UI kit components
import {Button} from '@hangry/react-ui-kit';

import './style.scss';

function ErrorPage(props) {
	const history = useHistory();
	return (
		<div className="ErrorPage">
			<div className="error-content">
				<img
					src={require('assets/images/error.svg')}
					alt="error-page"
					className="error-image"
				/>
				<div className="error-title">{localization.ErrorPage.title}</div>
				<div className="error-description">
					{localization.ErrorPage.description}
				</div>
				<div className="button-container">
					<Button
						type="primary"
						label={localization.ErrorPage.buttonSecondary}
						variant="outlined"
						className="button-ui-kit"
						size="large"
						onClick={() => sendMessageToNative({key: 'contactUs'})}
					/>
					<Button
						type="primary"
						label={localization.ErrorPage.buttonPrimary}
						className="button-ui-kit"
						size="large"
						onClick={() => history.go()}
					/>
				</div>
			</div>
		</div>
	);
}

export default ErrorPage;
