import history from './history';
import {sendMessageToNative} from './utils';

export const goBack = () => {
	history.goBack();
	sendMessageToNative({key: 'goBack'});
};

export const goTo = (path, params = {}) => {
	history.push(path, params);
	sendMessageToNative({key: 'push'});
};

export const replace = (path, params = {}) => {
	history.replace(path, params);
	sendMessageToNative({key: 'replace'});
};
