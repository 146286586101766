import {get} from 'lodash';

export const LOCAL_STORAGE_USER = 'user';

export const RELEASE_STAGE = get(process.env, 'REACT_APP_RELEASE_STAGE', 'development');

const isProductionBuild = RELEASE_STAGE === 'production';
export const URLS = {
	POINTS_SERVICE_BASE_URL: isProductionBuild
		? 'https://api.ishangry.com/point/gql'
		: 'https://api-dev.ishangry.com/point/gql',
	PROMO_SERVICE_BASE_URL: isProductionBuild
		? 'https://api.ishangry.com/loyalty/v1/promo'
		: 'https://api-dev.ishangry.com/loyalty/v1/promo',
	AUTH_SERVICE_BASE_URL: isProductionBuild
		? 'https://api.ishangry.com/user'
		: 'https://api-dev.ishangry.com/user',
	ORDER_SERVICE_BASE_URL: isProductionBuild
		? 'https://api.ishangry.com/order/api/v1'
		: 'https://api-dev.ishangry.com/order/api/v1',
};

export const COOKIE_NAMES = {
	QR_CODE_DATA: 'qrCodeData',
	SIGN_UP_WITH_REFERRAL: 'signUpWithReferral',
	AUTH_KEY: 'token',
	PROMO_AUTH_KEY: 'promoToken',
	ORDER_AUTH_KEY: 'orderToken',
	USER_AUTH_KEY: 'authToken',
	SHOW_NPS_SURVEY: 'showNPSSurvey',
};

export const ACTIONS = {
	SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
	REMOVE_AUTH_TOKEN: 'REMOVE_AUTH_TOKEN',
	SET_PROMO_AUTH_TOKEN: 'SET_PROMO_AUTH_TOKEN',
	REMOVE_PROMO_AUTH_TOKEN: 'REMOVE_PROMO_AUTH_TOKEN',
	SET_USER_AUTH_TOKEN: 'SET_USER_AUTH_TOKEN',
	REMOVE_USER_AUTH_TOKEN: 'REMOVE_USER_AUTH_TOKEN',
	SET_ORDER_AUTH_TOKEN: 'SET_ORDER_AUTH_TOKEN',
	REMOVE_ORDER_AUTH_TOKEN: 'REMOVE_ORDER_AUTH_TOKEN',
	SET_TOAST_MESSAGE: 'SET_TOAST_MESSAGE',
	REMOVE_TOAST: 'REMOVE_TOAST',
	SET_FROM_NATIVE_APP: 'SET_FROM_NATIVE_APP',
	SET_IS_NATIVE_WITHOUT_TAB: 'SET_IS_NATIVE_WITHOUT_TAB',
	SET_USER_DATA: 'SET_USER_DATA',
	SET_REMOTE_CONFIG: 'SET_REMOTE_CONFIG',
};

export const ERROR_CODES = {
	INSUFFICIENT_POINT: 'GEN201',
	INSUFFICIENT_REWARD_STOCK: 'GEN202',
	BLOCKED_USER: 'GEN301',
	INSUFFICIENT_DEPOSIT: 'GEN_S01',
	OPERATOR_ISSUE: 'GEN_S02',
	THIRD_PARTY_DOWN: 'GEN_S03',

	// Scan
	POINT_SCANNED_BY_SELF: 'GEN101',
	POINT_SCANNED_BY_OTHERS: 'GEN102',
	POINT_CAN_CLAIM_AFTER_MINUTES: 'GEN103',
	POINT_INJECTED_ALREADY: 'GEN105',
	POINT_CLAIMED_BY_SELF: 'GEN106',
	POINT_CLAIMED_BY_OTHERS: 'GEN107',
	POINT_CLAIM_BILL_NOT_FOUND: 'GEN108',
	POINT_CLAIM_TOTAL_MISMATCH: 'GEN109',
	POINT_DRIVER_APP_DETECTED: 'GEN302',
};

export const THUMBS_RATING = {
	BAD: 'Bad',
	GOOD: 'Good',
	EXCELLENT: 'Excellent',
};