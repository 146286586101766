import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

import loggerMiddleware from '../middleware/logger';
import rootReducer from './reducers';

const middlewares = [thunkMiddleware];
if (process.env.NODE_ENV !== 'production') {
	middlewares.unshift(loggerMiddleware);
}

const middlewareEnhancer = applyMiddleware(...middlewares);

const composer = process.env.NODE_ENV !== 'production' ? composeWithDevTools : compose;

const enhancers = [middlewareEnhancer];
const composedEnhancers = composer(...enhancers);

const store = createStore(rootReducer, composedEnhancers);

export default store;