import {ACTIONS} from 'utils/constants';

const initialState = {
	token: null,
	promoToken: null,
	authToken: null,
	orderToken: null,
	fromNativeApp: false,
	isNativeWithoutTab: false,
	user: {},
};

export default function auth(state = initialState, {type, payload}) {
	switch (type) {
	case ACTIONS.SET_AUTH_TOKEN:
		return {
			...state,
			token: payload.token,
		};
	case ACTIONS.REMOVE_AUTH_TOKEN:
		return initialState;
	case ACTIONS.SET_PROMO_AUTH_TOKEN:
		return {
			...state,
			promoToken: payload.token,
		};
	case ACTIONS.REMOVE_PROMO_AUTH_TOKEN:
		return initialState;
	case ACTIONS.SET_USER_AUTH_TOKEN:
		return {
			...state,
			authToken: payload.token,
		};
	case ACTIONS.REMOVE_USER_AUTH_TOKEN:
		return initialState;
	case ACTIONS.SET_ORDER_AUTH_TOKEN:
		return {
			...state,
			orderToken: payload.token,
		};
	case ACTIONS.REMOVE_ORDER_AUTH_TOKEN:
		return initialState;
	case ACTIONS.SET_FROM_NATIVE_APP:
		return {
			...state,
			fromNativeApp: payload,
		};
	case ACTIONS.SET_IS_NATIVE_WITHOUT_TAB:
		return {
			...state,
			isNativeWithoutTab: payload,
		};
	case ACTIONS.SET_USER_DATA:
		return {
			...state,
			user: payload,
		};
	default:
		return state;
	}
}
