export const pageview = () => {
	window.fbq && window.fbq('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const track = (name, options = {}) => {
	window.fbq && window.fbq('track', name, options);
};

export const trackCustom = (name, options = {}) => {
	window.fbq && window.fbq('trackCustom', name, options);
};
