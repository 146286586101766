import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import {pxToVwMaxed} from 'utils/utils';

function PulseLoader(props) {
	return (
		<div
			className={`loader ${props.className}`}
			style={props.style}>
			{[1, 2, 3].map(i => (
				<div
					key={i}
					style={{width: pxToVwMaxed(props.size || 0), height: pxToVwMaxed(props.size || 0), backgroundColor: props.color}} />
			))}
		</div>
	);
}

PulseLoader.defaultProps = {
	color: 'black',
	size: 12,
	className: '',
};

PulseLoader.propTypes = {
	color: PropTypes.string,
	size: PropTypes.number,
	className: PropTypes.string,
};

export default PulseLoader;
