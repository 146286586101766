// import firebase from 'firebase/app';

import * as fbq from 'utils/fbPixel';
// import {isFromNative} from 'utils/utils';

import store from 'store';

import packageJson from '../../package.json';

class Analytics {
	constructor() {
		this.defaultProperties = {
			appVersion: packageJson.version,
			timestamp: `${new Date().getTime()}`,
			scope: 'Loyalty',
		};
	}

	initiateAnalytics() {
		// this.defaultProperties.isFromNative = isFromNative();
		// this.Analytics = firebase.analytics();
		// this.setUserProperties();
	}

	setUserProperties() {
		if (this.Analytics) {
			const {user} = store.getState().auth || {};
			if (user.id) {
				this.defaultProperties.userId = user.id;
				this.Analytics.setUserId(user.id);
			}
			this.Analytics.setUserProperties(this.defaultProperties);
		}
	}

	logEvent(eventName, param = {}) {
		if (this.Analytics) {
			try {
				const eventParam = {...this.defaultProperties, ...param};
				this.Analytics.logEvent(eventName, eventParam);
				fbq.trackCustom(eventName, param);
			} catch (e) {
				console.log(e);
			}
		}
	}

	pageview() {
		if (this.Analytics) {
			this.Analytics.logEvent('page_view', this.defaultProperties);
		}
		fbq.pageview();
	}
}

const analytics = new Analytics();

export default analytics;
