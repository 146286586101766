import React, {lazy, Suspense, useEffect} from 'react';
import '@hangry/react-ui-kit/dist/index.css';
import {Router, Switch, Route, Redirect} from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import {Button, Bottomsheet, ActionModal} from '@hangry/react-ui-kit';

// Hooks
import {useToken} from 'hooks';

// Global Utils
// import {initiateFirebase} from 'utils/firebase';
import BugsnalClass from 'utils/logger';
import 'utils/firebase/remoteConfig';
import history from 'utils/history';
import {
	detectFromNativeApp,
	detectUserData,
	isFromNative,
	onLoginTokenReceived,
	importRetry,
} from 'utils/utils';
import {COOKIE_NAMES, RELEASE_STAGE} from 'utils/constants';
import analytics from 'utils/analytics';

// Components
import Toaster from 'components/Toaster';
import PulseLoader from 'components/Display/PulseLoader';
import ErrorPage from 'components/Display/ErrorPage';
import HangryLogin from 'components/HangryLogin';

import './style.scss';

function App() {
	smoothscroll.polyfill();

	useToken(); // Declared to save loyalty token on local storage
	useToken(COOKIE_NAMES.PROMO_AUTH_KEY); // Declared to save promo token on local storage
	useToken(COOKIE_NAMES.USER_AUTH_KEY); // Declared to save promo token on local storage
	useToken(COOKIE_NAMES.ORDER_AUTH_KEY); // Declared to save promo token on local storage

	detectFromNativeApp();
	detectUserData();
	const initApp = async () => {
		await BugsnalClass.initialize();
		// await initiateFirebase();
		analytics.initiateAnalytics();
	};
	// Hooks
	useEffect(() => {
		initApp().then(() => null);
		analytics.pageview();
		history.listen(() => {
			analytics.pageview();
		});
	}, []);

	const Loading = () => {
		return (
			<div className="loading-loadable">
				<PulseLoader />
			</div>
		);
	};

	const MainPage = lazy(() => importRetry(() => import('./containers/MainPage')));
	const PointHistoryPage = lazy(() => importRetry(() => import('./containers/PointHistoryPage')));
	const VoucherListPage = lazy(() => importRetry(() => import('./containers/VoucherListPage')));
	const MyCouponPage = lazy(() => importRetry(() => import('./containers/MyCouponPage')));
	const VoucherDetailPage = lazy(() =>
		importRetry(() => import('./containers/VoucherDetailPage')),
	);
	const MerchandiseRedemptionPage = lazy(() =>
		importRetry(() => import('./containers/MerchandiseRedemptionPage')),
	);
	const ScanQR = lazy(() => importRetry(() => import('./containers/ScanQR')));
	const ScanResult = lazy(() => importRetry(() => import('./containers/ScanResult')));
	const ScanInstruction = lazy(() => importRetry(() => import('./containers/ScanInstruction')));
	const ClaimPointPage = lazy(() => importRetry(() => import('./containers/ClaimPointPage')));
	const HCUPage = lazy(() => importRetry(() => import('./containers/HCUPage')));
	const Rating = lazy(() => importRetry(() => import('./containers/RatingPage')));
	const RatingResult = lazy(() => importRetry(() => import('./containers/RatingPage/RatingResult')));

	return (
		<div className="App">
			{!isFromNative() && (
				<HangryLogin
					ref={ref => (window.hangryAuthRef = ref)}
					onSuccess={onLoginTokenReceived}
					debugUrl={RELEASE_STAGE}
					refreshTokenOnMount
				/>
			)}
			<Toaster />
			<Suspense fallback={<Loading />}>
				<Router history={history}>
					<Switch>
						<Route
							exact
							path="/login">
							<ErrorPage />
						</Route>

						<Route
							exact
							path="/">
							<MainPage />
						</Route>
						<Route path="/history/:preferredTab?">
							<PointHistoryPage />
						</Route>
						<Route path="/coupon/:tab">
							<MyCouponPage />
						</Route>
						<Route path="/coupon">
							<MyCouponPage />
						</Route>
						<Route path="/voucher-list/:index?">
							<VoucherListPage />
						</Route>
						<Route path="/coupon-view/:voucherId?">
							<VoucherDetailPage />
						</Route>
						<Route path="/voucher-view/:voucherId?">
							<VoucherDetailPage />
						</Route>
						<Route path="/merchandise-redeem">
							<MerchandiseRedemptionPage />
						</Route>
						<Route path="/scan">
							<ScanQR />
						</Route>
						<Route path="/scan-result">
							<ScanResult />
						</Route>
						<Route path="/scan-instruction">
							<ScanInstruction />
						</Route>
						<Route path="/claim-point">
							<ClaimPointPage />
						</Route>
						<Route path="/hcu">
							<HCUPage />
						</Route>
						<Route path="/rating">
							<Switch>
								<Route path="/rating/result">
									<RatingResult />
								</Route>
								<Route
									path="/rating">
									<Rating />
								</Route>
							</Switch>
						</Route>
						<Route path="*">
							<Redirect to="/" />
						</Route>
					</Switch>
				</Router>
			</Suspense>
		</div>
	);
}

Button.defaultProps = {
	...Button.defaultProps,
	scaled: true,
};
ActionModal.defaultProps = {
	...ActionModal.defaultProps,
	scaled: true,
};
Bottomsheet.defaultProps = {
	...ActionModal.defaultProps,
	scaled: true,
};

export default App;
